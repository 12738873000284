import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="S" transform="translate(10, 10)"> {/* Adjusted positioning */}
        <path
          d="
            M38,28
            C34,28 31,30 31,34
            C31,38 34,40 38,40
            L45,40
            C50,40 53,43 53,46
            C53,50 50,53 45,53
            C40,53 36,50 36,46
            H32
            C32,52 37,56 42,56
            L45,56
            C52,56 58,52 58,46
            C58,41 52,38 45,38
            L38,38
            C35,38 33,36 33,34
            C33,32 35,30 38,30
            L45,30
            C48,30 50,32 50,34
            H54
            C54,29 48,27 45,27
            L38,27
            C34,27 31,29 31,34
          "
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="
          M50, 5
          L11, 27
          L11, 72
          L50, 95
          L89, 73
          L89, 28 z
        "
      />
    </g>
  </svg>
);

export default IconLoader;
