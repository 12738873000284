import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import './hero-section.css';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 95vh;
  height: 95vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: calc(var(--nav-height) / 2);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hey, my name is</h1>;
  const two = <h2 className="big-heading">Salman.</h2>;
  const three = <h3 className="big-heading">I build things for the web.</h3>;
  const four = (
    <>
      <p>
        I&apos;m a Computer Engineer with expertise in Data Science, Next.js, and React Native.
        Passionate about crafting innovative solutions and building dynamic data driven
        applications. I&apos;m currently focusing on building a natural language-based relational database querying engine{' '}
        <a href="https://askdb.salmann.dev/" target="_blank" rel="noreferrer">
          AskDB
        </a>
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="https://www.linkedin.com/in/muhammad-salman-2288b11b8/"
      target="_blank"
      rel="noreferrer">
      Check out my linkedin!
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <div className="abc">
      {/* Left Section */}
      <div className="left-t">
        <StyledHeroSection className="xxx">
          {prefersReducedMotion ? (
            <>
              {items.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </>
          ) : (
            <TransitionGroup component={null}>
              {isMounted &&
                items.map((item, i) => (
                  <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                    <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
                  </CSSTransition>
                ))}
            </TransitionGroup>
          )}
        </StyledHeroSection>
      </div>

      {/* Right Section */}
      <div className="right-t">
        <div className="hero-terminal">
          <div className="terminal-header">
            <div className="button close"></div>
            <div className="button minimize"></div>
            <div className="button maximize"></div>
          </div>

          <div id="loader">
            <div id="loaded"></div>
          </div>

          <div className="terminal">
            <p className="delay1">
              <span style={{ color: 'red' }}>import</span>{' '}
              <span style={{ color: '#50FA7B' }}>Developer</span>{' '}
              <span style={{ color: '#FF79C6' }}>from</span>{' '}
              <span style={{ color: '#F8F8F2' }}>'./Developer';</span>
            </p>
            <p className="delay2">
              <span style={{ color: '#F8F8F2' }}>class</span>{' '}
              <span style={{ color: '#50FA7B' }}>Salman</span>{' '}
              <span style={{ color: '#F8F8F2' }}>extends</span>{' '}
              <span style={{ color: '#50FA7B' }}>Developer</span>{' '}
              <span style={{ color: '#F8F8F2' }}>&#123;</span>
            </p>
            <p className="delay3">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;constructor()</span>{' '}
              <span style={{ color: '#F8F8F2' }}>&#123;</span>
            </p>
            <p className="delay4">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;super();</span>
            </p>
            <p className="delay5">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.age = </span>
              <span style={{ color: '#FF79C6' }}>new Date().getFullYear() - 2004;</span>
            </p>
            <p className="delay6">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.languages = </span>
              <span style={{ color: '#F8F8F2' }}>[</span>
              <span style={{ color: '#50FA7B' }}>'TypeScript'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'Python'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'C++'</span>
              <span style={{ color: '#F8F8F2' }}>];</span>
            </p>
            <p className="delay7">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.tools = </span>
              <span style={{ color: '#F8F8F2' }}>[</span>
              <span style={{ color: '#50FA7B' }}>'Next.js'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'Express'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'GraphQL'</span>{' '}
              <span style={{ color: '#F8F8F2' }}>];</span>
            </p>
            <p className="delay8">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.projects = </span>
              <span style={{ color: '#F8F8F2' }}>[</span>
              <span style={{ color: '#50FA7B' }}>'Portfolio Website'</span>{''}
              <span style={{ color: '#F8F8F2' }}>];</span>
            </p>
            <p className="delay9">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.values = </span>
              <span style={{ color: '#F8F8F2' }}>&#123;</span>
            </p>
            <p className="delay10">
              <span style={{ color: '#F8F8F2' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;teamOriented:
              </span>{' '}
              <span style={{ color: '#50FA7B' }}>10,</span>
            </p>
            <p className="delay11">
              <span style={{ color: '#F8F8F2' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;determination:
              </span>{' '}
              <span style={{ color: '#50FA7B' }}>10,</span>
            </p>
            <p className="delay12">
              <span style={{ color: '#F8F8F2' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;curiosity:
              </span>{' '}
              <span style={{ color: '#50FA7B' }}>10,</span>
            </p>
            <p className="delay13">
              <span style={{ color: '#F8F8F2' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;lifelongLearning:
              </span>{' '}
              <span style={{ color: '#50FA7B' }}>10</span>
            </p>
            <p className="delay14">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;&#125;;</span>
            </p>
            <p className="delay15">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&nbsp;&nbsp;this.hobbies = </span>
              <span style={{ color: '#F8F8F2' }}>[</span>
              <span style={{ color: '#50FA7B' }}>'Photography'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'Traveling'</span>,{' '}
              <span style={{ color: '#50FA7B' }}>'Football'</span>
              <span style={{ color: '#F8F8F2' }}>];</span>
            </p>
            <p className="delay16">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;&#125;</span>
            </p>
            <p className="delay17">
              <span style={{ color: '#F8F8F2' }}>&nbsp;&nbsp;code(hrs) &#123;</span>{' '}
              <span style={{ color: '#50FA7B' }}>return hrs &gt;= 8;</span>{' '}
              <span style={{ color: '#F8F8F2' }}>&#125;</span>
            </p>
            <p className="delay18">
              <span style={{ color: 'red' }}>export</span>
              <span style={{ color: '#F8F8F2' }}> default </span>
              <span style={{ color: '#50FA7B' }}>new</span>{' '}
              <span style={{ color: '#50FA7B' }}>Salman();</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
