import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(10.000000, 6.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(-2, 0)">
          {' '}
          {/* Moves the "S" slightly to the left */}
          <path
            d="
              M38,28
              C34,28 31,30 31,34
              C31,38 34,40 38,40
              L45,40
              C50,40 53,43 53,46
              C53,50 50,53 45,53
              C40,53 36,50 36,46
              H32
              C32,52 37,56 42,56
              L45,56
              C52,56 58,52 58,46
              C58,41 52,38 45,38
              L38,38
              C35,38 33,36 33,34
              C33,32 35,30 38,30
              L45,30
              C48,30 50,32 50,34
              H54
              C54,29 48,27 45,27
              L38,27
              C34,27 31,29 31,34
            "
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
